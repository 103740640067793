import React from 'react';
import {
  Accordion,
  Column,
  Container,
  Layout,
  LayoutBlock,
  Hero,
  Title,
  Description,
  Row,
  Section,
  Actions,
} from '@dragonchain-dev/dragon-kit';
import { Layout as PageLayout, SEO, TypeForm, FeatureRow } from '../../components/_index';

import Archive from '../../assets/images/icons/archive.svg';
import Brain from '../../assets/images/icons/brain-thought.svg';
import Eye from '../../assets/images/icons/eye.svg';
import Touch from '../../assets/images/icons/touch.svg';
import CardSecure from '../../assets/images/icons/card-secure.svg';
import Shield from '../../assets/images/icons/shield.svg';

const SupplyChain = () => {
  return (
    <PageLayout>
      <SEO
        title="Case study: Premium Consumer Goods in Supply Chain"
        description="Provably ensure to your customer that premium consumer goods maintained the high quality they
        may expect, all the way to the exact roasting data captured by IoT devices."
      />
      <Layout dark background="blue">
        <Hero
          type="code"
          backgroundImage="https://images.dragonchain.com/texture/clouds-blue@2x.jpg"
          video={{
            url: 'https://www.youtube.com/watch?v=85Kbf6tMUl0',
            autoPlay: true,
          }}
        >
          <Title color="white">Case Study: Premium Consumer Goods in Supply Chain and Manufacturing</Title>
          <Description color="blue-light">
            <p>
              How Dragonchain delivered a solution for Knowledge Perk in 2 weeks, to prove to their customers
              that their coffee is produced to the high quality they expect, down to the exact roasting data
              captured by IoT devices.
            </p>
            <Actions
              actions={[
                {
                  type: 'link',
                  name: 'See Example',
                  color: 'blue',
                  hover: 'blue-dark',
                  to: 'https://kp.dragonchain.com/v1/products/fae63503-6c91-469d-ae8f-d977f66c8e23',
                  blank: true,
                },
              ]}
            />
          </Description>
        </Hero>
      </Layout>
      <LayoutBlock separator>
        <Container>
          <Row gap="small">
            <Column span={12}>
              <Section title="From Concept To Operational System In 2 Weeks" />
            </Column>
            <FeatureRow
              features={[
                {
                  Icon: Archive,
                  title: 'Man-hours',
                  body: '116 man-hours, 3 resources, and 4 days of development.',
                },
                {
                  Icon: Eye,
                  title: 'Smart Contract',
                  body: 'Additional transaction types to pull and store roasting data.',
                },
                {
                  Icon: Touch,
                  title: 'Blockchain',
                  body: 'Two operational business blockchains.',
                },
                {
                  Icon: CardSecure,
                  title: 'Consulting',
                  body: 'Ideas brought to life with the expertise of both parties.',
                },
                {
                  Icon: Shield,
                  title: 'Website',
                  body: 'Custom designed mobile-first website to host the landing pages.',
                },
                {
                  Icon: Brain,
                  title: 'Product Labeling Solution',
                  body: 'A unique QR code on each product containing exact batch information.',
                },
              ]}
            />
            <Container>
              <Row gap="small">
                <Column span={12}>
                  <Section title="How it started">
                    One of the developers at Dragonchain learned about Knowledge Perk through Rock Hill’s
                    technology incubator. After learning about their vision and meeting with Ryan Sanderson,
                    Co-founder and CEO of Knowledge Perk, lunch quickly turned into a mutual understanding of
                    how blockchain could help increase transparency in the last journey of the premium
                    small-batch coffee supply chain.
                  </Section>
                </Column>
              </Row>
              <Row gap="small">
                <Column span={12}>
                  <Section title="Brainstorm">
                    We brainstormed several ideas (payments, loyalty, product reviews, sourcing, roasting) and
                    settled on providing transparency into their roasting process. Ryan described how the
                    small-batch coffee roasting process is completed by hand. And even though a recipe is
                    followed for each specific roast profile, there was no means to provide customers
                    assurance that the high quality coffee purchased matched with the batch. For example, if a
                    batch is roasted and the roast goes off recipe (heat, airflow, bean types, etc), most
                    roasters will sample the product, and if the flavor is similar, sell the product
                    regardless.
                  </Section>
                </Column>
              </Row>
              <Row gap="small">
                <Column span={12}>
                  <Section subtitle="From fair-trade, to fair-roast" title="Transparency">
                    Knowledge Perk wanted to be the first to provide transparency in the space by labeling
                    each bag with a unique QR code. This proves to the customer that the recipe matches the
                    roast profile on the bag of coffee beans. The roast data is obtained through IoT devices
                    in the roaster, and directly stored on the blockchain.
                  </Section>
                </Column>
              </Row>
            </Container>
          </Row>
          <Row gap="large">
            <Column span={12}>
              <Accordion title="The stats">
                <Accordion.Item title="Man-hours">
                  Data exploration and discovery: 8 hours. QR code research: 3 hours. Dragonchain setup and
                  preparation: 2 hours. Smart contract development and setup: 16 hours. Dragonchain
                  authentication service updates: 2 hours. Website design and mockups: 11 hours. Website
                  project preparation: 4 hours. Website development: 30.5 hours. Standard API improvements,
                  specific routes, deployment: 40 hours. Additional onsite support for product labeling: 4
                  hours.
                </Accordion.Item>
                <Accordion.Item title="Knowledge Perk Deliverables">
                  Single login to Cropster for a member of the Dragonchain solutions team. API keys to
                  Cropster. Access to Knowledge Perk’s team to educate Dragonchain’s team. Access to Knowledge
                  Perk’s web admin. A printer capable of printing labels.
                </Accordion.Item>
                <Accordion.Item title="Dragonchain Deliverables">
                  <ul>
                    <li>Blockchain</li>
                    <li>Smart Contract</li>
                    <li>API</li>
                    <li>Website</li>
                    <li>Hosting</li>
                  </ul>
                </Accordion.Item>
              </Accordion>
            </Column>
          </Row>
          <Row>
            <Column span={6}>
              <Section title="Want to learn more?">
                Integrate Dragonchain's blockchain solutions in your supply chain system or platform. Schedule
                a call with the creators to find out how blockchain based transparency can bring value to
                suppliers, brands, and end users of products.
              </Section>
            </Column>
            <Column span={6} right middle>
            <Actions
            actions={[
              {
                type: 'link',
                name: 'Contact Us',
                color: 'blue',
                hover: 'blue-dark',
                to: '/contact-us',
              },
            ]}
          />
            </Column>
          </Row>
        </Container>
      </LayoutBlock>
    </PageLayout>
  );
};

export default SupplyChain;
